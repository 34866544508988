import { MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import {
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  RefObject,
} from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import carImages from "../../../../assets/images/car";
import { TranslationContext } from "../../../../views/driverReservation/show/component";
import { ShowContext, TripForm } from "../../../../views/car/show/types";
import { Address } from "../../../../models/address/types";
import { tripSchema } from "../../../../views/car/show/form";
import { useFormik } from "formik";
import { createTrip } from "../../../../services/trip";
import { usePlacesWidget } from "react-google-autocomplete";
import { TripStatus, TripType } from "../../../../models/trip/types";
import { getUserId } from "../../../../services/localstorage";
import { useQuery } from "react-query";
import { getInsuranceAndRefresh } from "../../../../services/insurance";
import { getInsuranceDays, getTripDays } from "../../../helpers/dateHandler";
import {
  addressPrice,
  addressValue,
  numberSeparator,
} from "../../../helpers/textHandler";
import { CarUbication } from "../carUbication/component";
import { placeComponentCrash } from "../../../../views/home/types";
import {
  getNewAddress,
  handleAutocompleteInput,
} from "../../../helpers/mapsActions";
import { LoginContinueModal } from "../../../../components/modals/loginContinue/component";
import { AxiosError } from "axios";
import { CardInformation } from "./cardInfo/CardInfo";
import { CarInformation } from "./carInformation/CarInformation";
import {
  isValidHostC,
  isValidHostCOwnInsurance,
} from "../../../helpers/status";
import {
  getHostByUserId,
  getHostExistByUserId,
} from "../../../../services/host";
import { getDestinationsById } from "../../../../services/car";
import { useDisableDates } from "../../../../hooks/disableDates";
import { useBlockDates } from "../../../../hooks/blockDates";
import { EditDateBlock } from "../editCarPrice/types";
import { useHours } from "../../../../hooks/hours";
import { useAddressTypes } from "../../../../hooks/address";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TooltipIcon } from "../../../../components/tooltips/component";
import { useBlockNextHalfHours } from "../../../../hooks/car/show/blockNextHalfHours";
import { greenwichToMilitarTime } from "../../../helpers/timeHandler";
import Geocode from "react-geocode";
import { InputAutocomplete } from "../../inputAutocomplete/components";
import {
  mockEndDate,
  mockInitialDate,
} from "../../../../components/navHomeFilter/form";
import { TrustedUser } from "../../../../models/user/types";
import { getUser } from "../../../../services/user";
import { Trans } from "react-i18next";
import { InsuranceType } from "../../../../models/insurance/types";

interface CarFormProps {
  setErrorMsg: Dispatch<SetStateAction<string | null>>;
  setOpenAlert: Dispatch<SetStateAction<boolean>>;
  preview?: boolean;
}

export const CarForm = ({
  setErrorMsg,
  setOpenAlert,
  preview,
}: CarFormProps) => {
  const { car, host, addresses } = useContext(ShowContext);
  const { home, main, extra } = useAddressTypes(addresses || []);
  const t = useContext(TranslationContext);
  const [where, setWhere] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [search] = useSearchParams();
  const { formatHours } = useHours(30);
  const [finalLeftHours, setFinalLeftHours] = useState<string[]>(formatHours);
  const [finalRightHours, setFinalRightHours] = useState<string[]>(formatHours);
  const [dates, setDates] = useState<DateRange<Dayjs>>([null, null]);
  const [blockDates, setBlockDates] = useState<EditDateBlock[]>([]);
  const [pickUpAddressInput, setPickUpAddressInput] = useState<string>("");
  const [returnAddressInput, setReturnAddressInput] = useState<string>("");

  useDisableDates({
    carId: car?.id.toString() || "0",
    blockDates,
    setBlockDates,
  });
  const { handleBlockDates } = useBlockDates({ blockDates, setDates });
  const [isLoginContinueModalOpen, setIsLoginContinueModalOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { data: isHostCommercial } = useQuery(
    ["isHostCommercial", host?.id],
    () => getHostExistByUserId(host?.id.toString() || ""),
    {
      enabled: !!host,
    }
  );

  useEffect(() => {
    if (formatHours) {
      setFinalLeftHours(formatHours);
      setFinalRightHours(formatHours);
    }
  }, [formatHours]);

  useEffect(() => {
    const dateInitial = search.get("dateInitial");
    const dateEnd = search.get("dateEnd");
    const hourInitial = search.get("hourInitial");
    const hourEnd = search.get("hourEnd");

    const setInitialAndEndDates = async () => {
      if (!blockDates) return;

      // Check in blockdates if are available those dates
      const availableDate = blockDates.some((date) =>
        date.dates.some((days) => days.format("DD/MM/YYYY") === dateInitial)
      );

      if (availableDate) return;

      if (!dateInitial || !dateEnd) {
        setDates([
          dayjs(formikTrip.values.pickUpDate),
          dayjs(formikTrip.values.returnDate),
        ]);
        return;
      }

      // Date initial has to be minor than date end
      // Both dates has to be higher than today
      if (
        dayjs().isAfter(
          dayjs(`${dateInitial} ${hourInitial}`, "DD/MM/YYYY HH:mm")
        ) ||
        dayjs().isAfter(dayjs(`${dateEnd} ${hourEnd}`, "DD/MM/YYYY HH:mm")) ||
        dayjs(dateInitial, "DD/MM/YYYY").isAfter(dayjs(dateEnd, "DD/MM/YYYY"))
      ) {
        setDates([
          dayjs(formikTrip.values.pickUpDate),
          dayjs(formikTrip.values.returnDate),
        ]);
        return;
      }

      formikTrip.setFieldValue("pickUpDate", dayjs(dateInitial, "DD/MM/YYYY"));
      formikTrip.setFieldValue("returnDate", dayjs(dateEnd, "DD/MM/YYYY"));

      setDates([
        dayjs(dateInitial, "DD/MM/YYYY"),
        dayjs(dateEnd, "DD/MM/YYYY"),
      ]);
    };

    const setInitialAndEndHours = async () => {
      if (!hourInitial && !hourEnd) return;
      formikTrip.setFieldValue("pickUpTime", hourInitial);
      formikTrip.setFieldValue("returnTime", hourEnd);
    };

    setInitialAndEndDates();
    setInitialAndEndHours();
  }, [search, blockDates]);

  useEffect(() => {
    const carLocation = async () => {
      if (!car || !addresses) return;
      const mainAddress = addresses.find((address) => address.type === 2);

      if (car.destinationId !== 0) {
        const destination = await getDestinationsById(car.destinationId || 0);
        destination && setWhere(destination.name);
      } else {
        setWhere(
          `${mainAddress?.city}, ${mainAddress?.state}, ${mainAddress?.country}`
        );
      }
    };

    carLocation();
  }, [car, addresses]);

  const { data: userLogged } = useQuery(["userLogged"], () => getUser());
  const { data: hostC } = useQuery(
    ["hostC", host?.id],
    () => getHostByUserId(host?.id.toString() || ""),
    {
      enabled: !!isHostCommercial && !!host?.id,
    }
  );

  const { data: insurance } = useQuery(
    ["InsuranceCar", car?.id],
    () => getInsuranceAndRefresh(car?.id),
    {
      enabled: !!car,
    }
  );

  const handleAddressFormat = (place: placeComponentCrash, type: string) => {
    formikTrip.setFieldValue(type + "Address", {
      ...place,
      geometry: {
        ...place.geometry,
        location: {
          lat: place.geometry.location.lat().toString(),
          lng: place.geometry.location.lng().toString(),
        },
      },
    });
  };

  const formatDates = (newValue: DateRange<Dayjs>) => {
    setDates(newValue);
    if (!newValue[0] || !newValue[1]) return;

    const startDate = dayjs(newValue[0]);
    const endDate = dayjs(newValue[1]);

    formikTrip.setFieldValue("pickUpDate", startDate);
    formikTrip.setFieldValue("returnDate", endDate);
  };

  const fetchTrip = async (values: TripForm) => {
    if (!localStorage.getItem("id")) {
      setIsLoginContinueModalOpen(true);
      return;
    }
    setLoading(true);
    if (!car || !host) return;

    try {
      const id = getUserId();
      const userId = id ? id : "0";

      // Register new address pickup if doesn't exist
      let pickUpId: number | undefined = values.pickUpAddressId;
      if (values.pickUpType == 2 && values.pickUpAddress) {
        const response = await getNewAddress(
          car.id,
          values.pickUpPrice,
          values.pickUpAddress
        );
        pickUpId = response ? response.id : undefined;
      }

      // Register new address return if doesn't exist
      let returnId: number | undefined = values.returnAddressId;
      if (values.returnType == 2 && values.returnAddress) {
        const response = await getNewAddress(
          car.id,
          values.returnPrice,
          values.returnAddress
        );
        returnId = response ? response.id : undefined;
      }

      if (!insurance || !pickUpId || !returnId) return;

      const newTrip = {
        carId: car.id,
        hostId: host.id,
        driverUserId: parseInt(userId),
        pickUpAddressId: pickUpId,
        returnAddressId: returnId,
        type: TripType.DEACTIVATED,
        status: TripStatus.CREATE_RESERVATION,
        createDate: dayjs(new Date()).format("YYYY-MM-DD"),
        pickUpDate: values.pickUpDate.format("YYYY-MM-DD"),
        pickUpTime: values.pickUpTime + ":00",
        returnDate: values.returnDate.format("YYYY-MM-DD"),
        returnTime: values.returnTime + ":00",
        policyNumber: "",
        carPrice: carFinalPrice.toString(),
        insurancePrice: insuranceAmount.toString(),
        pickUpReturnPrice: pickUpReturnFinalPrice.toString(),
        bookingFeePrice: bookingFeePrice.toString(),
        depositAmount: insurance.depositAmount || "",
        totalPrice: finalPrice.toString(),
        timeZone: "",
      };

      const response = await createTrip(newTrip);

      if (response && !response.data.id) {
        setErrorMsg(t("show.car_form.error_msg"));
        setOpenAlert(true);
        return;
      }
      const tripCode = response?.data.tripCode;
      navigate("/" + t("checkout", { ns: "routes" }) + "/" + tripCode);
    } catch (error) {
      if (error instanceof AxiosError) {
        setErrorMsg(
          error.response?.data.message || t("show.car_form.error_msg")
        );
        setOpenAlert(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const formikTrip = useFormik<TripForm>({
    initialValues: {
      pickUpPrice: 0,
      pickUpType: 3,
      pickUpAddressId: 0,
      pickUpAddress: {},
      returnPrice: 0,
      returnType: 3,
      returnAddressId: 0,
      returnAddress: {},
      pickUpDate: mockInitialDate,
      pickUpTime: "07:30",
      returnDate: mockEndDate,
      returnTime: "07:30",
    },
    validationSchema: tripSchema,
    onSubmit: fetchTrip,
  });

  useBlockNextHalfHours({
    componentName: "carForm",
    formatHours,
    setBlockDates,
    pickUpDate: formikTrip.values.pickUpDate,
    returnDate: formikTrip.values.returnDate,
    pickUpTime: formikTrip.values.pickUpTime,
    setFinalLeftHours,
    setFinalRightHours,
    setFields: formikTrip.setFieldValue,
  });

  const tripDays = getTripDays(
    formikTrip.values.pickUpDate,
    formikTrip.values.pickUpTime,
    formikTrip.values.returnDate,
    formikTrip.values.returnTime
  );

  const insuranceDays = getInsuranceDays(
    insurance?.type || InsuranceType.QUALITAS,
    formikTrip.values.pickUpDate,
    formikTrip.values.pickUpTime,
    formikTrip.values.returnDate,
    formikTrip.values.returnTime
  );

  const handleAddressSelector = (
    e: SelectChangeEvent<string>,
    type: string
  ) => {
    const data = e.target.value.split("-");
    formikTrip.setFieldValue(type + "AddressId", parseInt(data[0]));
    formikTrip.setFieldValue(type + "Price", parseFloat(data[2]));
    formikTrip.setFieldValue(type + "Address", {});
    type === "pickUp" ? setPickUpAddressInput("") : setReturnAddressInput("");
    formikTrip.setFieldValue(type + "Type", parseInt(data[1]));
  };

  const carFinalPrice = parseFloat(car ? car.price : "0") * tripDays || 0;
  const insuranceAmount =
    parseFloat(insurance?.customerPrice ? insurance?.customerPrice : "0") *
      insuranceDays || 0;
  const bookingFeePrice = 100;
  const pickUpReturnFinalPrice =
    formikTrip.values.pickUpPrice + formikTrip.values.returnPrice;
  const finalPrice =
    carFinalPrice + insuranceAmount + bookingFeePrice + pickUpReturnFinalPrice;

  const dayAndHour =
    dayjs(formikTrip.values.pickUpDate).format("MM/DD/YYYY") +
    " " +
    formikTrip.values.pickUpTime;

  const handleDates = (newValue: DateRange<dayjs.Dayjs>) => {
    if (!handleBlockDates(newValue)) {
      setErrorMsg(t("show.car_form.incorrect_date"));
      setOpenAlert(true);
      formikTrip.setFieldValue("pickUpDate", "");
      formikTrip.setFieldValue("returnDate", "");
      return;
    }

    formatDates(newValue);
  };

  const handleCurrentLocation = (type: string) => {
    navigator.geolocation.getCurrentPosition(function (position) {
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS || "");
      Geocode.fromLatLng(
        position.coords.latitude.toString(),
        position.coords.longitude.toString()
      ).then(
        (response) => {
          const firstResult = response.results[0];
          const res = {
            ...firstResult,
            geometry: { ...firstResult.geometry, viewport: "" },
          };
          if (type === "pickUp") {
            formikTrip.setFieldValue("pickUpAddress", res);
            setPickUpAddressInput(firstResult.formatted_address);
          } else {
            formikTrip.setFieldValue("returnAddress", res);
            setReturnAddressInput(firstResult.formatted_address);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    });
  };

  const formIndicatorInfo =
    hostC && isValidHostC(hostC) ? (
      <div className="indicator-information">
        <p className="indicator-name">
          {t("show.car_form.indicator.host_commercial_car")}
        </p>
        <span className="extra">
          {t("show.car_form.indicator.host_commercial_car_info")}
        </span>
      </div>
    ) : (
      <div className="indicator-information">
        <p className="indicator-name">
          {t("show.car_form.indicator.free_cancel")}
        </p>
        <span className="extra">
          {t("show.car_form.indicator.free_cancel_info") +
            dayjs(dayAndHour).subtract(1, "day").format("DD MMM, hh:mm A")}
        </span>
      </div>
    );

  const homeAddress = home.map((address: Address) => {
    return (
      <MenuItem
        value={addressValue(address)}
        style={{ whiteSpace: "normal" }}
        key={address.id + "-" + address.type + "-" + address.price}
      >
        {`${t("show.map.extra")} ${addressPrice(address)}`}
      </MenuItem>
    );
  });

  const mainAddress = main.map((address: Address) => {
    return (
      <MenuItem
        value={addressValue(address)}
        style={{ whiteSpace: "normal" }}
        key={address.id + "-" + address.type + "-" + address.price}
      >
        {`${t("show.map.main")} ${address.suburb} ${address.zipCode}, ${
          address.city
        }, ${address.state}, ${address.country} ${addressPrice(address)}`}
      </MenuItem>
    );
  });

  const extraAddress = extra.map((address: Address) => {
    return (
      <MenuItem
        style={{ whiteSpace: "normal" }}
        value={addressValue(address)}
        key={address.id + "-" + address.type + "-" + address.price}
      >
        {`📍 ${address.comercialName} ${addressPrice(address)}`}
      </MenuItem>
    );
  });

  const items = mainAddress.concat(extraAddress, homeAddress);

  const styles = {
    tripButton: {
      backgroundColor:
        formikTrip.isValid && formikTrip.dirty ? "#f76f8e" : "#828282",
    },
  };

  const { ref: pickUpAddressRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS,
    onPlaceSelected: (place) => {
      handleAddressFormat(place, "pickUp");
      setPickUpAddressInput(handleAutocompleteInput(place));
    },
    options: {
      componentRestrictions: { country: "mx" },
      fields: [
        "geometry",
        "formatted_address",
        "place_id",
        "address_components",
        "name",
      ],
      types: [],
    },
    language: "es-419",
  });

  const { ref: returnAddressRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS,
    onPlaceSelected: (place) => {
      handleAddressFormat(place, "return");
      setReturnAddressInput(handleAutocompleteInput(place));
    },
    options: {
      componentRestrictions: { country: "mx" },
      fields: [
        "geometry",
        "formatted_address",
        "place_id",
        "address_components",
        "name",
      ],
      types: [],
    },
    language: "es-419",
  });

  const depositWarning = () => {
    if (userLogged?.trustedUser !== TrustedUser.FALSE) return;

    return (
      <div className="warning">
        <div className="text-container">
          <img
            src={carImages.warningTriangle}
            alt=""
            className="alert-triangle"
          />

          <p>
            <Trans>
              {hostC && isValidHostCOwnInsurance(hostC)
                ? t("show.car_form.trip_form.warning", {
                    deposit: `$${numberSeparator(
                      parseFloat(insurance?.depositAmount || "0")
                    )}`,
                  })
                : t("show.car_form.trip_form.warning_personal", {
                    deposit: `$${numberSeparator(
                      parseFloat(insurance?.depositAmount || "0")
                    )}`,
                  })}
            </Trans>
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="car-form">
        <CardInformation />
        <div className="card">
          <div className="car-price">
            <h2 id="precio" className="price">
              $
              {numberSeparator(parseFloat(car?.price || "0")) +
                t("show.car_form.trip_form.price_placeholder")}
            </h2>
            <p id="precioprodia">
              {t("show.car_form.trip_form.insurnace_placeholder") +
                " $" +
                numberSeparator(parseFloat(insurance?.customerPrice || "0")) +
                t("show.car_form.trip_form.price_placeholder")}
            </p>
          </div>
          <div className="card-body">
            {where && (
              <div className="where-container">
                <img src={carImages.where} />
                <p className="where">
                  {t("show.car_form.trip_form.where", { where })}
                </p>
              </div>
            )}
            <div className="un-container-date">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                localeText={{ start: "", end: "" }}
              >
                <DateRangePicker
                  value={dates}
                  inputFormat="DD/MM/YYYY"
                  disablePast
                  shouldDisableDate={(date) =>
                    blockDates.some((dateList) =>
                      dateList.dates.some(
                        (dateItem) =>
                          dayjs(date).format("DD-MM-YYYY") ===
                          dayjs(dateItem).format("DD-MM-YYYY")
                      )
                    )
                  }
                  onChange={(newValue) => {
                    handleDates(newValue);
                  }}
                  renderInput={(startProps, endProps) => (
                    <div className="dates">
                      <div className="un-desde">
                        <label className="w-100">
                          {t("show.car_form.trip_form.since")}
                        </label>
                        <div className="d-flex align-items-center position-relative">
                          <TextField
                            {...startProps}
                            className="datepicker start"
                          />
                          {finalLeftHours.length !== 0 && (
                            <Select
                              labelId="time-left"
                              id="time-left"
                              label=""
                              className="hour"
                              defaultValue=""
                              value={formikTrip.values.pickUpTime}
                              onChange={(event) =>
                                formikTrip.setFieldValue(
                                  "pickUpTime",
                                  event.target.value
                                )
                              }
                            >
                              {finalLeftHours.map((hour) => (
                                <MenuItem
                                  key={greenwichToMilitarTime(hour)}
                                  value={greenwichToMilitarTime(hour)}
                                >
                                  {hour}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </div>
                        {formikTrip.touched.pickUpTime
                          ? formikTrip.errors.pickUpTime
                          : ""}
                      </div>
                      <div className="un-hasta">
                        <label>{t("show.car_form.trip_form.until")}</label>
                        <div className="d-flex align-items-center position-relative">
                          <TextField {...endProps} className="datepicker end" />
                          {finalRightHours.length !== 0 && (
                            <Select
                              labelId="time-right"
                              id="time-right"
                              label=""
                              className="hour"
                              defaultValue=""
                              value={formikTrip.values.returnTime}
                              onChange={(event) =>
                                formikTrip.setFieldValue(
                                  "returnTime",
                                  event.target.value
                                )
                              }
                            >
                              {finalRightHours.map((hour) => (
                                <MenuItem
                                  key={greenwichToMilitarTime(hour)}
                                  value={greenwichToMilitarTime(hour)}
                                >
                                  {hour}
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                        </div>
                        {formikTrip.touched.returnTime
                          ? formikTrip.errors.returnTime
                          : ""}
                      </div>
                    </div>
                  )}
                />
              </LocalizationProvider>
            </div>
            <form>
              <div className="delibery">
                <label>
                  {t("show.car_form.trip_form.delivery_devolution.delivery")}
                </label>
                <Select
                  labelId=""
                  id=""
                  label="delivery"
                  value={
                    formikTrip.values.pickUpAddressId +
                    "-" +
                    formikTrip.values.pickUpType +
                    "-" +
                    formikTrip.values.pickUpPrice
                  }
                  onChange={(e) => handleAddressSelector(e, "pickUp")}
                  className="dropdown"
                >
                  <MenuItem value={"0-3-0"} disabled>
                    {t("show.car_form.option")}
                  </MenuItem>
                  {items}
                </Select>
                <span className="info-alert">
                  {t("show.car_form.trip_form.delivery_devolution.alert")}
                </span>
              </div>

              <div
                className="ubication-input flex-wrap"
                style={{
                  display:
                    formikTrip.values.pickUpType == 2 &&
                    formikTrip.values.pickUpAddressId != 0
                      ? "flex"
                      : "none",
                }}
              >
                <label>{t("show.car_form.trip_form.your_direction")}</label>
                <div className="d-flex justify-content-between">
                  <div>
                    <InputAutocomplete
                      ref={pickUpAddressRef as RefObject<null>}
                      handleAutocomplete={(e) =>
                        setPickUpAddressInput(e.currentTarget.value)
                      }
                      autocomplete={pickUpAddressInput}
                    />
                  </div>
                  <button
                    id="btnCurrentLocation"
                    type="button"
                    className="ml-4 btn btn-place"
                    onClick={() => handleCurrentLocation("pickUp")}
                  >
                    <img src={carImages.place} alt="" />
                  </button>
                </div>
              </div>

              <div className="dropdown-divider"></div>
              <div className="delibery">
                <label>
                  {t("show.car_form.trip_form.delivery_devolution.devolution")}
                </label>
                <Select
                  labelId=""
                  id=""
                  value={
                    formikTrip.values.returnAddressId +
                    "-" +
                    formikTrip.values.returnType +
                    "-" +
                    formikTrip.values.returnPrice
                  }
                  onChange={(e) => handleAddressSelector(e, "return")}
                  label="devolution"
                  className="dropdown"
                >
                  <MenuItem value={"0-3-0"} disabled>
                    {t("show.car_form.option")}
                  </MenuItem>
                  {items}
                </Select>
                <span className="info-alert">
                  {t("show.car_form.trip_form.delivery_devolution.alert")}
                </span>
              </div>

              <div
                className="ubication-input-dev flex-wrap"
                style={{
                  display:
                    formikTrip.values.returnType == 2 &&
                    formikTrip.values.returnAddressId != 0
                      ? "flex"
                      : "none",
                }}
              >
                <label>{t("show.car_form.trip_form.your_direction")}</label>
                <div className="d-flex justify-content-between">
                  <div>
                    <InputAutocomplete
                      ref={returnAddressRef as RefObject<null>}
                      handleAutocomplete={(e) =>
                        setReturnAddressInput(e.target.value)
                      }
                      autocomplete={returnAddressInput}
                    />
                  </div>
                  <button
                    id="btnCurrentLocationDev"
                    type="button"
                    className="ml-4 btn btn-place"
                    onClick={() => handleCurrentLocation("return")}
                  >
                    <img src={carImages.place} alt="" />
                  </button>
                </div>
              </div>
            </form>
            <div className="list-prices">
              <div className="dropdown-divider"></div>
              <div className="extra">
                <div className="day-tag">
                  <h5 data-testid="car-label">
                    {t("show.car_form.trip_form.car")}
                  </h5>
                  <TooltipIcon
                    title={t("show.car_form.information_tooltips.use_days")}
                    icon={<InfoOutlinedIcon />}
                    classes="tooltip-form"
                  />
                  <p className="price-day">
                    $
                    {parseFloat(car?.price || "0").toFixed(2) +
                      ` / día (x${tripDays})`}
                  </p>
                </div>
                <span id="totalDays">
                  ${numberSeparator(+carFinalPrice.toFixed(2))}
                </span>
              </div>
              <div className="dropdown-divider"></div>
              <div className="extra">
                <div className="day-tag">
                  <h5 data-testid="insurance-label">
                    {t("show.car_form.trip_form.insurance")}
                  </h5>
                  <TooltipIcon
                    title={t("show.car_form.information_tooltips.insurance")}
                    icon={<InfoOutlinedIcon />}
                    classes="tooltip-form"
                  />
                  <p className="insurance-day">
                    $
                    {parseFloat(insurance?.customerPrice || "0").toFixed(2) +
                      ` / día (x${insuranceDays})`}
                  </p>
                </div>
                <span id="seguro">
                  ${numberSeparator(+insuranceAmount.toFixed(2))}
                </span>
              </div>
              <div className="dropdown-divider"></div>
              <div className="extra">
                <div className="day-tag">
                  <h5 data-testid="booking_fee-label">
                    {t("show.car_form.trip_form.booking_fee")}
                  </h5>
                  <TooltipIcon
                    title={t("show.car_form.information_tooltips.comission")}
                    icon={<InfoOutlinedIcon />}
                    classes="tooltip-form"
                  />
                </div>
                <span id="bookingFee">${numberSeparator(bookingFeePrice)}</span>
              </div>
              <div className="dropdown-divider"></div>
              <div className="extra">
                <div className="day-tag">
                  <h5 data-testid="delivery-return-label">
                    {t("show.car_form.trip_form.delivery_return")}
                  </h5>
                  <TooltipIcon
                    title={t("show.car_form.information_tooltips.prices")}
                    icon={<InfoOutlinedIcon />}
                    classes="tooltip-form"
                  />
                </div>
                <span id="entregaDevolucion">
                  ${numberSeparator(+pickUpReturnFinalPrice.toFixed(2))}
                </span>
              </div>
              <div className="dropdown-divider"></div>
              <div className="extra">
                <h5 data-testid="total-cost-label">
                  {t("show.car_form.trip_form.total_cost")}{" "}
                </h5>

                <span id="total" className="final-total-static">
                  MXN <p>${numberSeparator(+finalPrice.toFixed(2))}</p>
                </span>
              </div>
            </div>
            {depositWarning()}

            <div className="terms">
              <div className="text-container">
                <a
                  target="_blank"
                  href={
                    "https://upcdn.io/12a1xrm/raw/balety.com/documents/terminos-y-condiciones-de-uso.pdf"
                  }
                >
                  {t(
                    "show.car_form.trip_form.terms_and_conditions_platform_use"
                  )}
                </a>
              </div>
              <div className="text-container">
                <a
                  target="_blank"
                  href={
                    "https://upcdn.io/12a1xrm/raw/balety.com/documents/contrato-de-arrendamiento.pdf"
                  }
                >
                  {t("show.car_form.trip_form.leasing_contract")}
                </a>
              </div>
            </div>
            <div className="btn-des">
              <button
                disabled={
                  preview ||
                  loading ||
                  !(formikTrip.isValid && formikTrip.dirty)
                }
                type="button"
                className="btn btn-orange startRent"
                style={styles.tripButton}
                onClick={() => {
                  if (location.search != "?preview") formikTrip.handleSubmit();
                }}
              >
                {t("show.car_form.trip_form.continue")}
              </button>
            </div>
            <div className="form-indicator">
              <img src={carImages.thumbsUp} />
              {formIndicatorInfo}
            </div>
          </div>
        </div>
        <div className="car-info">
          <CarInformation />
          <div className="ubication">
            <h4>{t("show.car_form.ubication.title")}</h4>
            <p className="map-info">
              {t("show.car_form.ubication.map_delivery_and_return")}
            </p>
          </div>
        </div>
      </div>
      <CarUbication />
      <div className="total-fixed">
        <div className="total-container">
          <h2 style={{ fontSize: "16px" }}>{t("show.total_fixed.title")}</h2>
          <p className="final-total">
            <span>${numberSeparator(finalPrice) + " MXN"}</span>
          </p>
        </div>
        <button
          type="button"
          id="continuar"
          disabled={
            preview || loading || !(formikTrip.isValid && formikTrip.dirty)
          }
          className="btn btn-orange startRent"
          style={styles.tripButton}
          onClick={() => {
            if (location.search !== "?preview") formikTrip.handleSubmit();
          }}
        >
          {t("show.car_form.trip_form.continue")}
        </button>
      </div>
      <LoginContinueModal
        isOpen={isLoginContinueModalOpen}
        setOpen={() => setIsLoginContinueModalOpen(false)}
        setErrorMsg={setErrorMsg}
        setOpenAlert={setOpenAlert}
      />
    </>
  );
};
